import * as React from "react"
import Layout from "../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  fixRelativePath,
  applyHeadingStyles,
  buildPageDescription,
} from "../lib/helper"
import FormattedTitle from "../components/common/FormattedTitle"
import { SEO as SearchEngineOptimization } from "../components/common/seo"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"

const BasicPage = props => {
  const { pageContext } = props
  const { title, body, image = {}, thumbnail = {} } = pageContext
  const processedBody = fixRelativePath(body?.processed || "")
  const formattedBody = applyHeadingStyles(processedBody)
  return (
    <Layout>
      <h1>
        <FormattedTitle forceUnderline={true}>{title}</FormattedTitle>
      </h1>
      <div
        className="content-wrapper container position-relative"
        style={{ zIndex: "2" }}
      >
        <div className="gatsby-image py-4">
          <GatsbyImage
            alt={image?.alt || title}
            image={getImage(thumbnail)}
            loading="eager"
            className="rounded-4"
            placeholder="blurred"
            style={{
              width: "100%",
            }}
            title={image?.title || title}
          />
          {image?.title && <div className="figure-caption">{image?.title}</div>}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: formattedBody }}
          className="node-body py-4 clearfix"
        />
      </div>
      <AnimationCircleWhite
        style={{ top: "10%" }}
        className="animation animation-circle-white-1"
      />
      <AnimationCirclePink
        style={{ top: "80%" }}
        className="animation animation-circle-pink-1"
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          top: "30%",
          left: "-150px",
          width: "60px",
        }}
      />
      <AnimationElement2
        className="animation"
        style={{
          top: "50%",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
    </Layout>
  )
}

export default BasicPage

export const Head = ({ pageContext }) => {
  const { title, body, entityUrl } = pageContext
  const processedBody = fixRelativePath(body?.processed || "")
  const description = buildPageDescription(processedBody)
  return (
    <SearchEngineOptimization
      title={title}
      description={description}
      pathname={entityUrl}
    />
  )
}
